<template>
  <div class="carousel-button" @click="$emit('click', verifiedType)">
    <img :src="arrowIcon" alt="" />
  </div>
</template>

<script>
export default {
  name: 'CarouselButton',
  props: {
    type: {
      type: String,
      default: 'left', // or right
    },
  },
  computed: {
    verifiedType() {
      return this.type === 'left' ? 'left' : 'right';
    },
    arrowIcon() {
      return `/icons/icn_arrow_${this.verifiedType}.svg`;
    },
  },
};
</script>

<style scoped lang="scss">
.carousel-button {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 12px;
  background-color: #325978;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 530px) {
    height: 2rem;
    width: 2rem;
    border-radius: 0.5rem;

    & > img {
      height: 1rem;
      width: 0.5rem;
    }
  }
}
</style>
